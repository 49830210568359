import { Directive, ElementRef } from '@angular/core';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';

@Directive({
  selector: '[mat-blockInput]',
})
export class BlockedInputDirective {
  constructor(el: MatInput) {
    el.disabled = true;
  }
}
