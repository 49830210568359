import { CdkStepper } from '@angular/cdk/stepper';
import { Component, ViewEncapsulation } from '@angular/core';

/** Custom CDK stepper component */
@Component({
  template: `<ng-container *ngTemplateOutlet="selected ? selected.content : null"></ng-container>`,
  selector: 'app-client-project-picker-stepper',
  providers: [{ provide: CdkStepper, useExisting: ClientProjectPickerStepperComponent }],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'cp-picker-stepper',
  },
})
export class ClientProjectPickerStepperComponent extends CdkStepper {
  selectStepByIndex(index: number): void {
    this.selectedIndex = index;
  }
}
